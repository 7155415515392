import { Domain } from "../types/common";

export function isDevEnvironment(host: string): boolean {
  return (
    host.includes(".dev.") ||
    host.includes("-dev.") ||
    host.includes("localhost") ||
    false
  );
}

export function isDomain(domain: Domain, host: string, query: string) {
  if (
    domain === Domain.MEDIENECKE &&
    (host.includes("medien") ||
      (query === "medien" && host.includes("localhost")))
  )
    return true;
  else if (
    domain === Domain.PIXELWERKSTATT &&
    (host.includes("pixel") ||
      (query === "pixel" && host.includes("localhost")))
  )
    return true;
  else if (
    domain === Domain.KITAHUB &&
    !(
      host.includes("medien") ||
      (query === "medien" && host.includes("localhost"))
    ) &&
    !(
      host.includes("pixel") ||
      (query === "pixel" && host.includes("localhost"))
    )
  )
    return true;
  return false;
}
