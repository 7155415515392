import "../styles/globals.css";
import "../styles/style.scss";

import cookie from "cookie";
import * as React from "react";
import type { IncomingMessage } from "http";
import type { AppProps, AppContext } from "next/app";

import {
  SSRKeycloakProvider as SSRKeycloakProviderBase,
  SSRCookies,
} from "@react-keycloak/ssr";
import { collagen } from "../components/kita-home/CentralText/collagen";
import { shuffle } from "../utils/shuffle";
import { isDevEnvironment } from "../utils/common";

const SSRKeycloakProvider = SSRKeycloakProviderBase as any;

export interface InitialProps {
  cookies: Record<string, string>;
  host?: string;
  collagen: string[];
}

function Container({
  Component,
  pageProps,
  cookies,
  host,
  collagen,
}: AppProps & InitialProps) {
  const isDev = isDevEnvironment(host || "");
  const keycloakCfg = {
    url: isDev
      ? "https://idm-dev.kita.bayern/auth"
      : "https://idm.kita.bayern/auth",
    realm: "KITA",
    clientId: isDev ? "landingpage-dev" : "landingpage",
  };

  return (
    <SSRKeycloakProvider
      keycloakConfig={keycloakCfg}
      persistor={SSRCookies(cookies)}
    >
      <Component
        {...pageProps}
        host={host}
        isDev={isDev}
        collagen={collagen}
        hasAuthCookies={cookies["kcToken"] || cookies["kcIdToken"]}
      />
    </SSRKeycloakProvider>
  );
}

function parseCookies(req?: IncomingMessage) {
  if (!req || !req.headers) {
    return {};
  }
  return cookie.parse(req.headers.cookie || "");
}

Container.getInitialProps = async (
  context: AppContext
): Promise<InitialProps> => {
  return {
    collagen: shuffle(collagen),
    cookies: parseCookies(context?.ctx?.req),
    host: context?.ctx?.req?.headers.host || undefined,
  };
};

export default Container;
